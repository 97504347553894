/** Success code  */
export const ERROR_CODE_SUCCESS: number = 0;
export const ERROR_SDK_NOT_LOADED: number = 100;
/** User canceled during the login process. */
export const ERROR_OPERATION_CANCELLED: number = 200001;
/** When auto-login from email verification link policy
 * is activated, this response code is passed as the user
 * is redirected to the nextURL specified in the policy.
 * It is not indicative of an error. */
export const ERROR_CODE_SUCCESS_PENDING_AUTOLOGIN: number = 206005;
/** Unique identifier exists */
export const EMAIL_ALREADY_EXIST_CODE: number = 400003;
/** Invalid parameter value */
export const INVALID_PARAMETER_CODE: number = 400006;
/** The user ID that is passed is not valid for this site. */
export const ERROR_UNAUTHORIZED_USER: number = 403005;
/** A user passes an incorrect password or a login ID that doesn't exist */
export const ERROR_INVALID_LOGIN_ID: number = 403042;
export const ERROR_DISABLED_ACCOUNT: number = 403041;

/** The timestamp or expiration of the token used exceeded the allowed time window. */
export const ERROR_REQUEST_EXPIRED: number = 403002;
/** There is no user with that email or username. */
export const ERROR_NO_USER_EXIST: number = 403047;
/** Not fount */
export const ERROR_NOT_FOUND: number = 404000;
export const STATUS_SUCCESS_NO_CONTENT: number = 204;
export const STORE_KEY: string = 'login';
export const EMAIL_ALREADY_EXISTS_ERROR: string =
  'This email is already in use';
export const EMAIL_ALREADY_NOT_EXIST_ERROR: string =
  'There is no user with that email';
export const EMAIL_IS_NOT_VALIDATED: string = 'Email has not been validated';
export const INVALID_LOGIN_ID_ERROR_MESSAGE: string =
  'Email or password is incorrect. Please try again.';
export const DISABLED_ACCOUNT_ERROR =
  'An account with this email address has been closed. Please use a different email address.';
export const INVALID_PARAMETER_ERROR_DETAIL: string = 'regToken expired';
export const GENERIC_ERROR_CODE: number = -1;
export const GENERIC_ERROR_MESSAGE: string =
  'An error occured while connecting to node server.';
export const GIGYA_CUSTOM_ERROR_MESSAGE =
  'Gigya error: Invalid LoginID code: 403042';
export const DISABLED_ACCOUNT_ERROR_MESSAGE =
  'Gigya error: Account Disabled code: 403041';
